import { RefObject } from 'react';
import { useSearchLocationContext } from '../../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import clsx from 'clsx';
import { useGroupedDistrictsQuery } from '../../../../shared/services/locations/locations.service';

import type {
  IDistrict,
  IGroupedDistrict
} from '../../../../shared/models/location/district.model';

import styles from './../../SearchForm.module.css';

interface IDistrictsListProps {
  labelRef: RefObject<HTMLLabelElement>;
}

export default function DistrictsList({ labelRef }: IDistrictsListProps) {
  const { data: countries } = useGroupedDistrictsQuery();
  const { setDistrict } = useSearchLocationContext();

  if (!countries || countries?.length === 0) {
    return null;
  }

  const handleClick = (district: IDistrict) => {
    labelRef.current?.click();
    setDistrict(district);
  };

  return (
    <div
      className={clsx(styles.noScrollbar, styles.list, styles.districtsList)}
    >
      {countries.map(country => (
        <Country key={country.id} data={country} handleClick={handleClick} />
      ))}
    </div>
  );
}

interface ICountryProps {
  data: IGroupedDistrict;
  handleClick: (district: IDistrict) => void;
}

export function Country({ data, handleClick }: ICountryProps) {
  const { name, cities } = data;

  if (!cities || cities?.length === 0) {
    return null;
  }

  return (
    <>
      <span className={styles.countryTag}>{name}</span>
      {cities.map(city => (
        <City key={city.id} district={city} handleClick={handleClick} />
      ))}
    </>
  );
}

interface ICityProps {
  district: IDistrict;
  handleClick: (district: IDistrict) => void;
}

function City({ district, handleClick }: ICityProps) {
  const { district: currentDistrict } = useSearchLocationContext();
  const { id, title } = district;

  return (
    <button
      key={id}
      onClick={() => handleClick(district)}
      className={styles.districtsListItem}
    >
      <span
        className={clsx(
          styles.noSelect,
          id === currentDistrict?.id && styles.bold
        )}
      >
        {title}
      </span>
      <input
        type="checkbox"
        className={styles.selectedDistrictCheckbox}
        onChange={() => null}
        checked={id === currentDistrict?.id}
      />
    </button>
  );
}
