import React, { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { ILocation } from '../../shared/models/location/location.model';
import { useSearchLocationContext } from '../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useSearchFormContext } from '../../shared/components/common/SearchLocation/SearchForm.Provider';
import { transformSpanishChars } from '../../lib/specialChars';
import SearchFormDistrictSelect from './parts/SearchFormDistrictSelect';
import {
  SearchFormButtons,
  SearchFormInput,
  SearchFormResults,
  SearchFormTags
} from './parts';
import { useInstantAgentPlaceIdHelper } from '../../shared/hooks/locations/useInstantAgentPlaceIdHelper';

import styles from './SearchForm.module.css';

interface ISearchFormProps {
  value?: string;
  inputRef?: any;

  onButtonClick?: () => any;
  onChange: (str: string) => any;
  buttonDisabled?: boolean;
  inputDisabled?: boolean;

  classNames?: {
    root?: string;
    input?: string;
    cancelIcon?: string;
    searchButton?: string;
  };

  isExplore: boolean;
  results?: ILocation[] | false;
}

export const SearchForm: React.FC<ISearchFormProps> = ({
  value,
  onButtonClick,
  buttonDisabled,
  inputDisabled,
  onChange,
  inputRef,
  classNames,
  isExplore,
  results
}) => {
  const { district, searchTerm, selectedLocations } =
    useSearchLocationContext();
  const { isInputFocused, isCitySelectOpen } = useSearchFormContext();
  useInstantAgentPlaceIdHelper();

  useEffect(() => {
    if (!district && isInputFocused) {
      inputRef.current?.blur();
    }
  }, [district, isInputFocused]);

  const locationsResults = useMemo(() => {
    if (!value) return results || [];
    if (!results) return [];

    const searchVal = transformSpanishChars(value.toLowerCase());

    return results.filter(location => {
      const placeTitle = transformSpanishChars(
        location.placeTitle.toLowerCase()
      );

      return placeTitle.includes(searchVal);
    });
  }, [value, results]);

  return (
    <div
      className={clsx(
        styles.container,
        isExplore ? styles.exploreViewContainer : styles.resultsViewContainer,
        isInputFocused && styles.inputFocused,
        searchTerm && styles.hasSearchTerm,
        isCitySelectOpen && styles.citySelectOpen,
        selectedLocations.length && styles.hasLocationTags
      )}
    >
      <SearchFormDistrictSelect />
      <div className={styles.formContainer}>
        <div className={clsx(styles.noScrollbar, styles.form)}>
          <SearchFormTags results={results} inputRef={inputRef} />
          <SearchFormInput
            inputRef={inputRef}
            value={value}
            inputDisabled={inputDisabled}
            onChange={onChange}
            onButtonClick={onButtonClick}
          />
          <SearchFormButtons
            inputRef={inputRef}
            onButtonClick={onButtonClick}
          />
        </div>
        <SearchFormResults inputRef={inputRef} results={locationsResults} />
      </div>
    </div>
  );
};
