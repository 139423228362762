import { useRef, useEffect } from 'react';
import { useSearchLocationContext } from '../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useSearchFormContext } from '../../../shared/components/common/SearchLocation/SearchForm.Provider';
import clsx from 'clsx';
import CurrentDistrict from './DistrictSelect/CurrentDistrict';
import DistrictsList from './DistrictSelect/DistrictsList';

import styles from './../SearchForm.module.css';

export const SearchFormDistrictSelect = () => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { district } = useSearchLocationContext();
  const { isInputFocused, isCitySelectOpen, setIsCitySelectOpen } =
    useSearchFormContext();

  useEffect(() => {
    if (!district && isInputFocused && checkboxRef.current) {
      checkboxRef.current.checked = true;
    } else if (isInputFocused && checkboxRef.current) {
      checkboxRef.current.checked = false;
    }

    setIsCitySelectOpen(checkboxRef?.current?.checked || false);
  }, [isInputFocused, district]);

  return (
    <div
      className={clsx(
        styles.districtSelectContainer,
        styles.searchInputCitySelectContainer,
        isCitySelectOpen && styles.citySelectOpen
      )}
    >
      <input
        id="district-select"
        type="checkbox"
        ref={checkboxRef}
        onChange={() => setIsCitySelectOpen(!isCitySelectOpen)}
        className={styles.checkboxInput}
        hidden
      />
      <CurrentDistrict labelRef={labelRef} district={district} />
      <DistrictsList labelRef={labelRef} />
    </div>
  );
};

export default SearchFormDistrictSelect;
