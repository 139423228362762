import { useState, useContext, createContext } from 'react';

import type { ReactNode } from 'react';

export interface ISearchFormContext {
  isInputFocused: boolean;
  setIsInputFocused: (isInputFocused: boolean) => any;
  isCitySelectOpen: boolean;
  setIsCitySelectOpen: (isCitySelectOpen: boolean) => any;
}

interface ISearchFormProviderParams {
  children: ReactNode;
}

export const SearchFormContext = createContext<ISearchFormContext>(
  {} as ISearchFormContext
);

export const SearchFormProvider = ({ children }: ISearchFormProviderParams) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isCitySelectOpen, setIsCitySelectOpen] = useState(false);

  const value = {
    isInputFocused,
    setIsInputFocused,
    isCitySelectOpen,
    setIsCitySelectOpen
  };

  return (
    <SearchFormContext.Provider value={value}>
      {children}
    </SearchFormContext.Provider>
  );
};

export const useSearchFormContext = () => useContext(SearchFormContext);
