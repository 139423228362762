import { FC, MouseEvent, RefObject } from 'react';
import { useTranslation } from 'next-i18next';
import { useSearchLocationContext } from '../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useSearchFormContext } from '../../../shared/components/common/SearchLocation/SearchForm.Provider';
import clsx from 'clsx';

import type { ILocation } from '../../../shared/models/location/location.model';

import styles from './../SearchForm.module.css';

export interface ISearchFormTagsProps {
  classNames?: {
    root?: string;
    item?: string;
  };
  results?: ILocation[] | false;
  inputRef?: RefObject<HTMLInputElement>;
}

export const SearchFormTags: FC<ISearchFormTagsProps> = ({
  results,
  inputRef
}) => {
  const { t } = useTranslation(['common']);
  const { selectedLocations, setSelectedLocations } =
    useSearchLocationContext();
  const { isInputFocused, setIsInputFocused } = useSearchFormContext();
  const showMore = !isInputFocused && selectedLocations.length > 1;

  const preventListClose = (e: MouseEvent) => {
    e.preventDefault();
  };

  const handleSearchTagClick = (placeId: number) => {
    setSelectedLocations(
      selectedLocations.filter(_placeId => _placeId !== placeId)
    );
  };

  const onContainerClick = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    if (!inputRef?.current || isInputFocused) return;

    inputRef?.current.focus();
  };

  return (
    <div
      className={clsx(
        styles.noScrollbar,
        styles.searchTags,
        showMore && styles.searchTagsMore
      )}
      onClick={e => onContainerClick(e)}
    >
      <button
        className={clsx(styles.searchTag, styles.searchTagMore)}
        onClick={() => inputRef?.current?.focus()}
      >
        <span className={clsx(styles.noSelect, styles.searchTagName)}>
          {t('tags-more', { count: selectedLocations.length - 1 })}
        </span>
      </button>
      {results &&
        selectedLocations.map(placeId => {
          const location = results.find(
            location => location.placeId === placeId
          );

          if (!location) return null;

          return (
            <button
              key={placeId}
              className={clsx(styles.searchTag)}
              onMouseDown={e => preventListClose(e)}
              onClick={() => handleSearchTagClick(placeId)}
            >
              <span className={clsx(styles.noSelect, styles.searchTagName)}>
                {!location.placeId && t('all')} {location.placeTitle}
              </span>
            </button>
          );
        })}
    </div>
  );
};
