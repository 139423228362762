export const transformSpanishChars = (str: string): string => {
  const specialChars: any = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    ñ: 'n',
    Ñ: 'N',
    ü: 'u',
    Ü: 'U',
    '¿': '?',
    '’': "'",
    '‘': "'"
  };

  return str.replace(
    /[áéíóúÁÉÍÓÚñÑüÜ¿!’‘]/g,
    char => specialChars[char] || char
  );
};
