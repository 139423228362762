import { ChangeEvent, MouseEvent, FC, RefObject } from 'react';
import { useTranslation } from 'next-i18next';
import { useSearchLocationContext } from '../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useSearchFormContext } from '../../../shared/components/common/SearchLocation/SearchForm.Provider';
import { transformSpanishChars } from '../../../lib/specialChars';
import clsx from 'clsx';

import type { ILocation } from '../../../shared/models/location/location.model';

import styles from './../SearchForm.module.css';

export interface ISearchFormResultsProps {
  onResultClick?: (result: ILocation) => void;
  results?: ILocation[];
  classNames?: {
    root?: string;
    item?: string;
  };
  inputRef: RefObject<HTMLInputElement>;
  isExplore?: boolean;
}

export const SearchFormResults: FC<ISearchFormResultsProps> = ({
  results,
  classNames,
  inputRef,
  isExplore
}) => {
  const { t } = useTranslation(['explore']);
  const { searchTerm, setSearchTerm, selectedLocations, setSelectedLocations } =
    useSearchLocationContext();
  const { isInputFocused } = useSearchFormContext();
  let _results;

  if (!results) return null;

  const handleInputLocationsClick = () => {
    inputRef.current?.focus();
  };

  const handleLocationCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    location: ILocation,
    reset: boolean
  ) => {
    const { placeId } = location;

    if (reset) {
      return setSelectedLocations([placeId]);
    }

    const selectedLocations_ = selectedLocations.filter(_placeId => !!_placeId);

    const wasSelected = !e.target.checked;

    if (wasSelected) {
      return setSelectedLocations(
        selectedLocations_.filter(_placeId => _placeId !== placeId)
      );
    }

    if (!wasSelected && searchTerm != '' && results.length === 1) {
      setSearchTerm('');
    }

    return setSelectedLocations([...selectedLocations_, placeId]);
  };

  if (results.length) {
    _results = results.map(location => {
      return (
        <label
          htmlFor={`location-${location.placeId}`}
          key={location.placeId}
          className={clsx(
            styles.locationsListItem,
            selectedLocations.includes(location.placeId) && styles.bold
          )}
          onClick={handleInputLocationsClick}
        >
          <span>
            {!location.placeId && t('all')}{' '}
            {getHighlightedText(location.placeTitle, searchTerm)}
          </span>
          <input
            type="checkbox"
            id={`location-${location.placeId}`}
            name={`location-${location.placeId}`}
            onChange={e =>
              handleLocationCheckboxChange(e, location, !location.placeId)
            }
            checked={selectedLocations.includes(location.placeId)}
          />
        </label>
      );
    });
  } else {
    _results = <span>{t('no-results')}</span>;
  }

  const preventListClose = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div
      className={clsx(
        styles.list,
        styles.locationsList,
        isInputFocused && styles.locationsListActive,
        isExplore ? styles.isExploreResultsList : styles.resultsList,
        classNames?.root
      )}
      onMouseDown={e => preventListClose(e)}
    >
      {_results}
    </div>
  );
};

const getHighlightedText = (text: string, highlight: string | undefined) => {
  if (!highlight) return <span>{text}</span>;

  const text_ = transformSpanishChars(text.toLowerCase());
  const highlight_ = transformSpanishChars(highlight.toLowerCase());

  const from = text_.indexOf(highlight_);
  const to = from + highlight_.length;

  if (from === -1) return <span>{text}</span>;

  const start = text.substring(0, from);
  const selected = text.substring(from, to);
  const end = text.substring(to, text.length);

  return (
    <span>
      {start}
      <span style={{ fontWeight: 'bold' }}>{selected}</span>
      {end}
    </span>
  );
};
