import { FC, RefObject, MouseEvent } from 'react';
import { useSearchLocationContext } from '../../../shared/components/common/SearchLocation/SearchLocation.Provider';
import { useSearchFormContext } from '../../../shared/components/common/SearchLocation/SearchForm.Provider';
import clsx from 'clsx';

import styles from './../SearchForm.module.css';

export interface ISearchFormButtonsProps {
  classNames?: {
    root?: string;
    item?: string;
  };
  inputRef: RefObject<HTMLInputElement>;
  onButtonClick?: () => any;
}

export const SearchFormButtons: FC<ISearchFormButtonsProps> = ({
  inputRef,
  onButtonClick
}) => {
  const { district, selectedLocations, setSelectedLocations } =
    useSearchLocationContext();
  const { isInputFocused, isCitySelectOpen } = useSearchFormContext();

  const closeLocationsList = () => {
    inputRef.current?.blur();
  };

  const preventListClose = (e: MouseEvent) => {
    e.preventDefault();
  };

  const onButtonClick_ = () => {
    if (district && selectedLocations.length) {
      onButtonClick?.();
      return inputRef.current?.blur();
    }

    if (!selectedLocations.length && isInputFocused) {
      setSelectedLocations([0]);
      return onButtonClick?.();
    }

    inputRef.current?.focus();
  };

  return (
    <div className={styles.formButtonsContainer}>
      <div
        className={clsx(
          styles.formButtons,
          isInputFocused && styles.formButtonsMove,
          selectedLocations.length && styles.formButtonsActive,
          isCitySelectOpen && styles.formButtonsHide
        )}
      >
        <button
          className={clsx(styles.formButton, styles.formSearchButton)}
          onClick={onButtonClick_}
          onMouseDown={e => preventListClose(e)}
        >
          <span className={styles.formButtonIcon}></span>
        </button>
        <button
          className={clsx(styles.formButton, styles.formCloseButton)}
          onClick={closeLocationsList}
          onMouseDown={e => preventListClose(e)}
        >
          <span className={styles.formButtonIcon}></span>
        </button>
      </div>
    </div>
  );
};
