import { FC, RefObject, KeyboardEvent, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useSearchFormContext } from '../../../shared/components/common/SearchLocation/SearchForm.Provider';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useSearchLocationContext } from '../../../shared/components/common/SearchLocation/SearchLocation.Provider';

import styles from './../SearchForm.module.css';

interface ISearchFormInputProps {
  value?: string;
  inputRef?: RefObject<HTMLInputElement>;
  inputDisabled?: boolean;
  onChange: (str: string) => any;
  onButtonClick?: () => any;
}

export const SearchFormInput: FC<ISearchFormInputProps> = ({
  value,
  inputRef,
  inputDisabled,
  onChange,
  onButtonClick
}) => {
  const { t } = useTranslation(['explore']);
  const { setIsInputFocused } = useSearchFormContext();
  const { setSearchTerm } = useSearchLocationContext();

  const _onChange = useMemo(
    () =>
      debounce((str: string) => {
        if (str !== value) {
          onChange(str);
        }
      }, 50),
    [onChange, value]
  );

  const onKeyDown = (e: KeyboardEvent) => {
    const { key, keyCode } = e;
    if (keyCode === 27 || key === 'Escape') {
      setSearchTerm('');

      if (inputRef?.current) {
        inputRef?.current.blur();
      }
    } else if (keyCode === 13 || key === 'Enter') {
      onButtonClick && onButtonClick();
    }
  };

  return (
    <input
      ref={inputRef}
      value={value}
      disabled={inputDisabled}
      onChange={e => _onChange(e.target.value || '')}
      onKeyDown={onKeyDown}
      onFocus={() => setIsInputFocused(true)}
      onBlur={() => setIsInputFocused(false)}
      placeholder={t('search-placeholder')}
      className={clsx(styles.searchInput)}
    />
  );
};
