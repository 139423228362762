import { useEffect } from 'react';
import {
  useLocationQuery,
  useSearchLocationContext
} from '../../components/common/SearchLocation/SearchLocation.Provider';

export function useInstantAgentPlaceIdHelper() {
  const { district, selectedLocations, setInstantAgentPlaceId } =
    useSearchLocationContext();
  const { data: results = [] } = useLocationQuery(
    district?.title || 'barcelona',
    {},
    false
  );

  useEffect(() => {
    const [firstLocation] = selectedLocations;

    if (!district) return;

    if (firstLocation) {
      setInstantAgentPlaceId(selectedLocations);
    } else if (results.length) {
      const place = results.find(
        location =>
          !location.isDistrict && location.placeTitle === location.districtTitle
      );

      if (place) {
        setInstantAgentPlaceId([place.placeId]);
      } else {
        const [district, place] = results;

        setInstantAgentPlaceId([place?.placeId]);
      }
    }
  }, [results, selectedLocations, district]);
}
