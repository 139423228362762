import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import { RefObject } from 'react';

import ChevronDownSVG from '../../../../public/assets/icons/chevron_down.svg';

import type { IDistrict } from '../../../../shared/models/location/district.model';

import styles from './../../SearchForm.module.css';

interface ICurrentDistrictProps {
  labelRef: RefObject<HTMLLabelElement>;
  district?: IDistrict;
}

export default function CurrentDistrict({
  labelRef,
  district
}: ICurrentDistrictProps) {
  const { t } = useTranslation(['explore']);

  return (
    <label
      ref={labelRef}
      htmlFor="district-select"
      className={styles.districtSelect}
    >
      <span
        className={clsx(
          styles.noSelect,
          styles.districtTitle,
          district && styles.selectedDistrict
        )}
      >
        {district ? district.title : t('select-city')}
      </span>
      <ChevronDownSVG className={styles.districtSelectIcon} />
    </label>
  );
}
